import React, { FC, useState, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Button,
} from 'reactstrap';
import { Table, TableActions, Select } from '../../components/index';
import { isValidOID, isValidHex } from '../../libs/helpers';
import {
  customExtensions as defaultValues,
  customExtensionEncodingOptions,
} from '../../libs/constants';
import { CustomExtension } from '../../libs/types';
import './Components.scss';
import { LabelValue } from '../../components/LabelValue/LabelValue';
import AwesomeCheckbox from '../../components/AwesomeCheckbox';

interface Props {
  id?: string;
  readOnly?: boolean;
  onChange?: Function;
  values: CustomExtension[];
}

const CustomExtensions: FC<Props> = ({
  id: _id = '',
  onChange = (): null => null,
  readOnly = false,
  values = defaultValues,
}) => {
  const [currentIdentifier, setCurrentIdentifier] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [currentCritical, setCurrentCritical] = useState(false);
  const [currentEncoding, setCurrentEncoding] = useState(
    customExtensionEncodingOptions[0].key
  );

  const invalidCurrentIdentifier =
    !_.chain(currentIdentifier).trim().isEmpty().value() &&
    !isValidOID(currentIdentifier);

  const invalidCurrentValue = ((): { invalid: boolean; reason: string } => {
    if (_.chain(currentValue).trim().isEmpty().value()) {
      return {
        invalid: false,
        reason: '',
      };
    }

    if (currentEncoding === 'hex_der_asn1') {
      return {
        invalid: !isValidHex(currentValue),
        reason: 'Insert a valid HEX value',
      };
    }
    if (currentEncoding === 'integer') {
      return {
        invalid: !_.isInteger(Number(currentValue)),
        reason: 'Insert a valid Integer value',
      };
    }
    if (currentEncoding === 'utf8_string') {
      return {
        invalid: !_.isString(currentValue),
        reason: 'Insert a valid UFT8 String value',
      };
    }
    if (currentEncoding === 'printable_string') {
      return {
        invalid: !_.isString(currentValue),
        reason: 'Insert a valid Printable String value',
      };
    }
    if (currentEncoding === 'ia5_string') {
      return {
        invalid: !_.isString(currentValue),
        reason: 'Insert a valid IA5 String value',
      };
    }
    return {
      invalid: true,
      reason: 'Insert a valid value',
    };
  })();

  const currentExtensionIsNotValid =
    invalidCurrentIdentifier ||
    invalidCurrentValue.invalid ||
    _.chain(currentValue).trim().isEmpty().value() ||
    _.chain(currentIdentifier).trim().isEmpty().value();

  const addExtension = (): void => {
    onChange({
      value: [
        ...values,
        {
          objectIdentifier: currentIdentifier,
          value: currentValue,
          encoding: currentEncoding,
          critical: currentCritical,
        },
      ],
    });
    setCurrentIdentifier('');
    setCurrentValue('');
  };

  const tableData = _.map(values, (item, index) => ({
    ...item,
    id: index,
    encoding: item?.encoding
      ? item.encoding
      : customExtensionEncodingOptions[0].value,
  }));

  const colums: any[] = [
    {
      dataField: 'objectIdentifier',
      text: 'Identifier',
    },
    {
      dataField: 'encoding',
      text: 'Encoding',
      formatter: (value: string): string => _.startCase(value),
    },
    {
      dataField: 'value',
      text: 'Value',
      formatter: (value: string, { id: rowId }: { id: string }): ReactNode => (
        <LabelValue value={value} label={rowId} copyable={true} plain={true} />
      ),
    },
    {
      dataField: 'critical',
      text: 'Critical',
      formatter: (value: boolean) => _.startCase(value.toString()),
    },
  ];

  if (!readOnly) {
    colums.push({
      dataField: 'aaa',
      text: '',
      formatExtraData: values, // this is needed due a bug into data table
      formatter: (
        notUsedValue: string,
        { id: rowId }: { id: any }
      ): ReactNode => {
        return (
          <TableActions
            rowId={String(rowId)}
            options={[
              {
                label: 'Delete',
                ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
                onClick: (): void => {
                  const newValue = _.filter(
                    values,
                    (item, index) => index !== rowId
                  );
                  onChange({ value: newValue });
                },
              },
            ]}
          />
        );
      },
    });
  }

  return (
    <div id={_id} className="CustomExtensions">
      <div className="custom-extensions mt-3">
        {!readOnly && (
          <div className="custom-extensions-input">
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label className="pki-label">Extension Identifier</Label>
                  <Input
                    id="custom-extensions-identifier"
                    value={currentIdentifier}
                    invalid={invalidCurrentIdentifier}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      const newValue: string = event.target.value;
                      setCurrentIdentifier(newValue);
                    }}
                    type="text"
                    name="custom-extensions-identifier"
                    placeholder="Add OID..."
                  />
                  <FormFeedback>Insert a valid OID</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={3}>
                <Select
                  label="Encoding"
                  disabled={readOnly}
                  selectedKey={currentEncoding}
                  onChange={({
                    value: newValue,
                    key: newKey,
                  }: {
                    value: string;
                    key: string;
                  }): void => {
                    setCurrentEncoding(newKey);
                  }}
                  options={customExtensionEncodingOptions}
                />
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label className="pki-label">Value</Label>
                  <Input
                    id="custom-extensions-value"
                    value={currentValue}
                    invalid={invalidCurrentValue.invalid}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      const newValue: string = event.target.value;
                      setCurrentValue(newValue);
                    }}
                    onKeyPress={(
                      event: React.KeyboardEvent<HTMLInputElement>
                    ): void => {
                      if (
                        event.key === 'Enter' &&
                        !currentExtensionIsNotValid
                      ) {
                        addExtension();
                      }
                    }}
                    type="text"
                    name="custom-extensions-value"
                    placeholder="Add Value..."
                  />
                  <FormFeedback>{invalidCurrentValue.reason}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup check inline className={'custom-control pl-0 mr-0'}>
                  <Label
                    className="pki-label"
                    for={'custom-extensions-critical-checkbox'}
                  >
                    Critical
                  </Label>
                  <AwesomeCheckbox
                    id="custom-extensions-critical-checkbox"
                    checked={currentCritical}
                    disabled={readOnly}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      setCurrentCritical(event.target.checked);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <Button
                  outline
                  className="float-right"
                  id="custom-extensions-button"
                  disabled={currentExtensionIsNotValid}
                  onClick={(): void => {
                    addExtension();
                  }}
                >
                  Add Extension
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
      <div className="custom-extensions-table">
        <Table
          keyField="id"
          id="custom-extensions-output"
          search={false}
          noDataIndication={
            readOnly ? 'No Custom Extensions' : 'Add a New Custom Extension'
          }
          data={tableData}
          columns={colums}
        />
      </div>
    </div>
  );
};

export default CustomExtensions;
